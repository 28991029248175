<template>
    <div class="my-4">
        <label class="block font-medium text-sm text-gray-700">
            {{ label ?? '' }}
        </label>
        <Select2 :name="name" style="width: 100%;"
                 class="font-sans w-full resize-none rounded-md border border-solid border-gray-300"
                 @change="myChangeEvent($event)" @select="mySelectEvent($event)"
                 v-model="selectedValue"
                 :options="options"></Select2>
    </div>
</template>
<script>
import Select2 from 'vue3-select2-component';
import {computed, ref, watchEffect} from 'vue';

export default {
    name: "UserSelection",
    components: {Select2},
    props:{
        label: {
            required: false,
            default: ''
        },
        modelValue:{
            required: true,
            type: Number,
            default: ''
        },
        value:{
            required: false,
            type: Number,
        },
        users: {
            required: false
        },
        name: {
            default: 'user_id'
        }
    },
    setup(props, context) {
        const selectedValue = ref( props.value ?? props.modelValue )
        const options = computed(()=>{
            let values =  props.users.map(role => {
                return {
                    id: role.id,
                    text: role.name
                }
            });

            return values;
        });

        function myChangeEvent(e){
            //console.log(e);
        }

        function mySelectEvent(e){
            console.log(e.id);
            context.emit('update:modelValue',e.id)
        }

        return{
            options,
            myChangeEvent,
            mySelectEvent,
            selectedValue
        }
    }
}
</script>

<style scoped>

</style>
