<template>
    <div class="my-4">
        <label class="block font-medium text-sm text-gray-700">
            {{ label ?? '' }}
        </label>
        <Select2 name="role" style="width: 100%;"
                 class="font-sans w-full resize-none rounded-md border border-solid border-gray-300"
                 v-model="selectedValue"
                 :options="roleOptions"></Select2>
    </div>
</template>

<script>
import Select2 from 'vue3-select2-component';
import {computed, ref} from 'vue';

export default {
    name: "RoleSelector",
    components: {Select2},
    props: {
        label: {
            required: false,
            default: ''
        },
        value:{
            required: false
        },
        roles: {
            required: false
        }
    },
    setup(props) {
        const selectedValue = ref(props.value.toString())
        const roleOptions = computed(()=>{
            let values =  props.roles.map(role => {
                return {
                    id: role.id,
                    text: role.name
                }
            });

           return values;
        });

        return{
            selectedValue,
            roleOptions
        }
    }
}
</script>

<style>
    .select2{
        width:100% !important;
    }
    .select2.select2-container {
        width: 100%;
    }
    .form-control {
        width: 100%;
    }
    .select2-selection__rendered {
        line-height: 39px !important;
        padding-left: 13px !important;
    }
    .select2-container .select2-selection--single {
        border: none !important;
        height: 42px !important;
    }
    .select2-selection__arrow {
        height: 42px !important;
    }
    .select2-container--default {
        border:none !important;
    }
</style>
