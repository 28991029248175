require('./bootstrap');
import {createApp} from "vue";
import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
const app = createApp({
    mounted: async () => {

    }
});
import componentRegistration from "../ts/componentRegistration";
componentRegistration(app);
app.mount('#app');
