<template>
    <div class="my-3">
        <label class="flex-1 block font-medium text-sm text-gray-700">
            {{ label ?? '' }}
        </label>
        <div class="flex flex-no-wrap">
            <span class="min-w-1/2 flex flex-no-wrap">
                <input type="text" :placeholder="placeholder" :name="name" class="grow rounded-l-lg font-sans border-gray-200 text-gray-700 font-sans"
                       @change="changed" v-model="modelValue" @keypress="isNumber($event)"  :min="min" :max="max"/>
                <div class="bg-slate-600 rounded-r-lg text-center font-sans flex items-center px-2" :class="{'w-10':suffix.length == 1}">
                    <span class="w-full text-white font-sans">{{suffix}}</span>
                </div>
            </span>

        </div>
    </div>
</template>

<script>
import {computed, ref, watch} from "vue";

export default {
    name: "MonetaryInput",
    props: {
        label: {
            required: false,
            default: ''
        },
        modelValue:{
            required: true,
            type: Number,
            default: ''
        },
        placeholder:{
            required:false,
            default:'0.00'
        },
        name: {
            required: false,
            default: ''
        },
        value:{
            required: false,
            type: Number,
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
    },
    setup(props, context) {
        const suffix = computed(() => {
          return  '€'
        })
        const min = computed(()=>{
            return 0
        })
        const max = computed(()=>{
            return 100000
        })

        watch(props.modelValue,(newV, oldV) => {
            let percentage = Number(newV)
            if(percentage < min.value){
                context.emit('update:modelValue', oldV)
            } else if (percentage > max.value){
                context.emit('update:modelValue', max.value)
            }
        })

        function isNumber(evt){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
        function changed(evt){
            var newV = evt.target.value
            newV = newV.replace(',','.')
            context.emit('update:modelValue', newV)
        }

        return {
            changed,
            isNumber,
            min,
            max,
            suffix
        }
    }
}
</script>

<style scoped>

</style>
