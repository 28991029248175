<template>
    <div class="my-4">
        <div class="flex items-center">
            <div @click="addCostSplit" class="cursor-pointer bg-indigo-500 text-white text-2xl rounded px-2 mr-2">+</div>
            <label class="flex-1 block font-medium text-sm text-gray-700">
                {{ label ?? '' }}
            </label>
        </div>

        <div class="form-check my-2">
            <input
                v-model="equalSplit"
                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox">
            <label class="form-check-label inline-block text-gray-800">
                Eerlijk delen
            </label>
        </div>

        <error-view class="my-2" v-for="error in errors" :message="error"></error-view>

        <input type="hidden" name="cost_splitting" :value="stringValue"/>

        <div class="flex flex-wrap max-w-1/3 gap-4 px-2">

            <div v-for="(val, index) in values">
                <div class="bg-red-600 text-white px-2" @click="removeValueAt(index)">x Remove</div>
                <user-selection
                    :users="users"
                    v-model="val.id"
                    name="">

                </user-selection>

                <monetary-input v-model="val.cost"></monetary-input>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, computed, ref, watchEffect} from "vue";
import UserSelection from "../User/UserSelection";
import ErrorView from "../General/ErrorView";
import MonetaryInput from "../Inputs/MonetaryInput";

export default {
    name: "CostSplitter",
    components: {MonetaryInput, ErrorView, UserSelection},
    props: {
        label: {
            required: false,
            default: ''
        },
        value: {
            required: false
        },
        users: {
            required: false
        },
        priceKey: {
            required: false,
            default: 'price'
        }
    },
    setup(props) {
        const values = ref([])
        const errors = ref([])
        const equalSplit = ref(true)
        const stringValue = computed(()=>{
            return JSON.stringify(values.value)
        })
        onMounted(() => {
            if (props.value != undefined && props.value.length > 0) {
                values.value = props.value.map(el => {
                    return {
                        id: el.id,
                        label: el.name,
                        cost: el.pivot.cost
                    }
                })
            }
        })

        watchEffect(()=>{
            checkSplitting()
        })

        function removeValueAt(index){
            values.value.splice(index, 1)
            checkSplitEqualy()
        }

        function findTotalCost(){
            let inputs = document.querySelectorAll('input[name="'+props.priceKey+'"]')
            if(inputs != null && inputs.length > 0)
                return inputs[0].value
        }

        function checkSplitting(){
            let costs = values.value.map(el=>el.cost)
            let sum = costs.reduce((a,b)=>Number(a)+Number(b),0);

            let total = findTotalCost()

            let tolerance = 0.09
            let difference = total - sum
            console.log(difference, total, sum)
            if(Math.abs(difference) < tolerance)
                errors.value = []
            else {
                if (difference < 0) {
                    errors.value = [
                        'Som is groter dan het totaal bedrag'
                    ]
                } else if (difference > 0) {
                    errors.value = [
                        'Som is kleiner dan het totaal bedrag'
                    ]
                }
            }
        }

        function checkSplitEqualy(){
            if(equalSplit.value){
                let total = findTotalCost();
                var split = total / values.value.length
                // round
                split = Math.round(split * 100) / 100;

                console.log('total: '+total, 'split: ' + split);
                for(let i=0;i<values.value.length;i++){
                    values.value[i].cost = split
                }
            }
        }

        function addCostSplit(){

            const usedUsers = values.value.map((a)=>a.id);
            const availableUser = props.users.filter((q) => {
                return !usedUsers.includes(q.id)
            })

            const newUser = availableUser[0]?.id ?? undefined
            values.value.push({
                id: newUser,
                label: undefined,
                cost: undefined
            })
           checkSplitEqualy()
        }

        return {
            values,
            stringValue,
            addCostSplit,
            errors,
            equalSplit,removeValueAt
        }
    }
}
</script>

<style scoped>

</style>
