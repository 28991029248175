<template>
    <div>
        <label class="flex-1 block font-medium text-sm text-gray-700">
            {{ label ?? '' }}
        </label>
        <input type="hidden" :name="name" :value="dateValue"/>
        <div class="flex flex-no-wrap my-3">
            <date-picker v-model:value="time" value-type="YYYY-MM-DD"></date-picker>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import {computed, onMounted, ref} from "vue";

export default {
    name: "DateInput",
    components: {DatePicker},
    props: {
        value: {
          required: false,
          default: ''
        },
        label: {
            required: false,
            default: ''
        },
        name: {
            required: false,
            default: ''
        }
    },
    setup(props){
        const time = ref(null)
        const dateValue = computed(()=>{
            console.log(time.value)
            return time.value;
        })

        onMounted(()=>{
            if(props.value != undefined)
                time.value = props.value
        })
        return {
            time,
            dateValue
        }
    }
}
</script>

<style scoped>

</style>
